<template>
<div>
    <Header />
    <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Súmate</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-10 bg-1">
            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col text-center mb-4 mb-md-0">
                        <img src="@/assets/img/brigadas4.jpg" alt="Donativos" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                            <h2 class="pb-3 mb-4"><b>Donativos en especie</b></h2>
                            <ul>
                                <li>Papel higiénico</li>
                                <li>Toallas sanitarias</li>
                                <li>Jabón corporal en barra</li>
                                <li>Tarjetas telefónicas</li>
                                <li>Toallas húmedas</li>
                                <li>Pañales de 0 a 3 años</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="apoyo" class="row row-cols-1 row-cols-md-2 pt-md-5 formulario">
                    <div class="col order-2 order-md-1">
                            <h2 class="pb-3 mb-4"><b>Apoyo voluntario</b></h2>
                        <ul class="nav nav-pills mb-4" id="myTab" role="tablist">
                            <li class="nav-item mr-2" role="presentation">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">VOLUNTARIOS</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">SERVICIO SOCIAL</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <p class="mb-5">Los voluntarios interesados en apoyar las actividades de la <b>Fundación Rebeca Lan</b> deben suministrar la siguiente información para ser contactados por nuestro equipo posteriormente.</p>
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Nombre</label>
                                            <input type="text" class="form-control" placeholder="Nombre">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Apellido</label>
                                            <input type="text" class="form-control" placeholder="Apellido">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Fecha de nacimiento</label>
                                            <input type="date" class="form-control" placeholder="dd/mm/aa">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Profesión u ocupación</label>
                                            <input type="text" class="form-control" placeholder="Profesión u ocupación">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Nacionalidad</label>
                                            <input type="text" class="form-control" placeholder="Nacionalidad">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Lugar de residencia</label>
                                            <input type="text" class="form-control" placeholder="Lugar de residencia">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Número telefónico</label>
                                            <input type="tel" class="form-control" placeholder="Número telefónico">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Correo electrónico</label>
                                            <input type="email" class="form-control" placeholder="Correo electrónico">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Redes sociales</label>
                                            <input type="text" class="form-control" placeholder="Redes sociales">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Lugar de residencia</label>
                                            <input type="text" class="form-control" placeholder="Lugar de residencia">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">De qué manera te gustaría apoyar?</label>
                                        <input type="text" class="form-control" placeholder="De qué manera te gustaría apoyar?">
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Mensaje</label>
                                        <textarea class="form-control" rows="3" placeholder="Por qué quieres ser voluntario en Fundación Rebeca Lan?"></textarea>
                                    </div>
                                    <button type="button" class="btn btn-secret">ENVIAR</button>

                                </form>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Nombre</label>
                                            <input type="text" class="form-control" placeholder="Nombre">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Apellido</label>
                                            <input type="text" class="form-control" placeholder="Apellido">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>Fecha de nacimiento</label>
                                            <input type="date" class="form-control" placeholder="dd/mm/aa">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Institución escolar</label>
                                            <input type="text" class="form-control" placeholder="Institución Escolar">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Grado de estudios</label>
                                            <input type="text" class="form-control" placeholder="Grado de Estudios">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Profesión que cursa</label>
                                            <input type="text" class="form-control" placeholder="Profesión que cursa">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Número telefónico</label>
                                            <input type="tel" class="form-control" placeholder="Número telefónico">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Correo electrónico</label>
                                            <input type="email" class="form-control" placeholder="Correo electrónico">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Redes sociales</label>
                                            <input type="text" class="form-control" placeholder="Redes sociales">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="sr-only">Lugar de residencia</label>
                                            <input type="text" class="form-control" placeholder="Lugar de residencia">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Días y horarios disponible</label>
                                        <input type="text" class="form-control" placeholder="Días y horarios disponible">
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Mensaje</label>
                                        <textarea class="form-control" rows="3" placeholder="Por qué quieres hacer tu servicio en Fundación Rebeca Lan?"></textarea>
                                    </div>
                                    <button type="button" class="btn btn-secret">ENVIAR</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col order-1 order-md-2 text-center mb-4 mb-md-0">
                        <img src="@/assets/img/voluntariado.jpg" alt="Súmate" class="img-fluid shadow rounded">
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/Templates/Header'
import Footer from '@/modules/Templates/Footer'

export default {
    name: "Nosotros",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

